import { AuthenticationStrategy } from '@mc-alberta/stark'
import {
  AuthenticationMethodType,
  AuthenticationReason,
  AuthenticationResult,
  AuthenticationStatus,
  AuthenticationSubject,
  ConsumerIdentityType
} from '@mc-alberta/types'
import SRCError from '../SRCError'
import { DEFAULT_PROGRAM_ID } from '../constants'
import nudetectWidgetData from '../helpers/extractNudetectWidgetData'
import post from '../post'
import transform from '../transform'
import utils from '../utils'
import validate from '../validate'
import { authenticate } from './authenticate'

/**
 * Determines if a consumer exists in the SRC system.
 */
export async function identityLookup({
  consumerIdentity // required
} = {}) {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'identityLookup'
    },
    state
  )
  validate.params({ consumerIdentity })
  validate.identity({ consumerIdentity })

  const message = {
    method: 'post',
    url: 'identities/lookup',
    headers: { ...state.headers },
    data: {
      // type is nolonger 'EMAIL', its 'EMAIL_ADDRESS'
      consumerIdentity: transform.identity.consumerIdentity.request(consumerIdentity)
    },
    ...nudetectWidgetData(state.isMastercardSrci, arguments)
  }
  const result = await post.send(message, state)
  validate.response(result, { 400: 'INVALID_PARAMETER' })
  // NOTE: FRAUD error will never be returned

  state.idLookupSessionId = result.idLookupSessionId

  const identityLookupResult = {
    consumerPresent: result.consumerPresent, // required
    ...(result.lastUsedCardTimestamp && { lastUsedCardTimestamp: result.lastUsedCardTimestamp })
  }

  if (!result.consumerPresent) {
    return identityLookupResult
  }

  state.stark.setAuthenticationInstance({
    ...state.stark.removeAuthenticationInstance({
      authenticationSubject: AuthenticationSubject.CONSUMER
    }),
    accountReference: {
      consumerIdentity
    },
    authenticationStrategies: {
      [AuthenticationReason.CONSUMER_IDENTITY_VALIDATION]: [AuthenticationStrategy.CONSUMER_OTP]
    },
    authenticationSubject: AuthenticationSubject.CONSUMER,
    idLookupSessionId: state.idLookupSessionId
  })
  await state.stark.loadAuthenticationMethods()

  return identityLookupResult
}

/**
 * Initializes a consumer-based OTP validation session.
 * This assumes that {@link identityLookup} was called prior, which identifies the consumer in question.
 *
 * @param params.requestedValidationChannelId - optional desired channel (`EMAIL_ADDRESS` or `MOBILE_PHONE_NUMBER`)
 */
export async function initiateIdentityValidation({
  requestedValidationChannelId // optional
} = {}) {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'initiateIdentityValidation'
    },
    state
  )

  // a specific validation channel may have been requested - covert it to method type and cache it
  state.requestedConsumerValidationType = (() => {
    switch (requestedValidationChannelId) {
      case ConsumerIdentityType.EMAIL_ADDRESS:
        return AuthenticationMethodType.EMAIL_OTP
      case ConsumerIdentityType.MOBILE_PHONE_NUMBER:
        return AuthenticationMethodType.SMS_OTP
    }
  })()

  // load the authentication methods for the consumer OTP
  await state.stark.loadAuthenticationMethods()

  // invoke authenticate() for OTP to initialize the session
  const authenticationState = await invokeOtpAuthenticate.call(this)

  if (authenticationState.authenticationStatus === AuthenticationStatus.NOT_SUPPORTED) {
    throw new SRCError({
      error: { status: 400, reason: 'OTP_SEND_FAILED', message: 'Failed to send OTP.' }
    })
  }

  // derive a list of `supportedValidationChannels` based on the list of available OTP methods
  const supportedValidationChannels = state.stark
    .getAuthenticationMethods(
      { authenticationSubject: AuthenticationSubject.CONSUMER },
      AuthenticationStrategy.CONSUMER_OTP
    )
    .map((authenticationMethod) => {
      const identityType = (() => {
        switch (authenticationMethod.authenticationMethodType) {
          case AuthenticationMethodType.EMAIL_OTP:
            return ConsumerIdentityType.EMAIL_ADDRESS
          case AuthenticationMethodType.SMS_OTP:
            return ConsumerIdentityType.MOBILE_PHONE_NUMBER
          default:
            // return null to indicate invalid IdentityType
            return null
        }
      })()

      return {
        identityProvider: DEFAULT_PROGRAM_ID,
        maskedValidationChannel: authenticationMethod.authenticationCredentialReference,
        identityType
      }
    })

  // if no auth method was provided, the backend service will automatically select one
  const selectedAuthenticationMethod = state.stark.getAuthenticationMethod(
    { authenticationSubject: AuthenticationSubject.CONSUMER },
    AuthenticationStrategy.CONSUMER_OTP
  )

  // store whatever the selected channel was in the state for subsequent calls to continue the session
  state.requestedConsumerValidationType = selectedAuthenticationMethod.authenticationMethodType

  return {
    supportedValidationChannels,
    maskedValidationChannel: selectedAuthenticationMethod?.authenticationCredentialReference
  }
}

/**
 * Validates the consumer OTP and returns authorization token(s) if successfully validated.
 * This assumes that {@link initiateIdentityValidation} was called prior to start the OTP session.
 *
 * @note This can generate a Merchant Recognition Token (MRT)
 *
 * @param params.validationData - the consumer-provided six-digit OTP
 * @param params.complianceSettings - set of compliance records; if REMEMBER_ME is provided, an MRT may be generated
 * @param params.appInstance - AppInstance record (only required when an MRT is requested)
 */
export async function completeIdentityValidation({
  validationData, // required
  complianceSettings, // optional
  appInstance // optional
} = {}) {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      params: arguments[0],
      methodName: 'completeIdentityValidation'
    },
    state
  )
  validate.params({ validationData })

  const authenticationState = await invokeOtpAuthenticate.call(this, {
    otpValue: validationData,
    complianceSettings,
    appInstance
  })

  if (authenticationState.authenticationResult === AuthenticationResult.AUTHENTICATED) {
    return {
      idToken: authenticationState.idToken,
      recognitionToken: authenticationState.recognitionToken
    }
  }

  throw new SRCError({
    error: {
      status: 400,
      reason: 'CODE_INVALID',
      message:
        'Client specified an invalid argument. Check error message and error details for more information.'
    }
  })
}

/**
 * Leverages the {@link authenticate} function to perform initialization and completion of the OTP session.
 *
 * @param methodAttributes - any `AuthenticationMethod`-specific method attributes (e.g., `complianceSettings`)
 */
async function invokeOtpAuthenticate(methodAttributes) {
  const authenticationMethodType = this.state.requestedConsumerValidationType
  const authenticationReason = AuthenticationReason.CONSUMER_IDENTITY_VALIDATION
  const authenticationInstance = this.state.stark.getAuthenticationInstance({
    authenticationSubject: AuthenticationSubject.CONSUMER
  })

  const payload = {
    ...authenticationInstance,
    authenticationReasons: [authenticationReason],
    authenticationMethod: authenticationMethodType && {
      authenticationMethodType,
      authenticationSubject: AuthenticationSubject.CONSUMER,
      methodAttributes
    },
    complianceSettings: methodAttributes?.complianceSettings
  }

  this.state.stark.useAuthenticationInstance({
    ...authenticationInstance,
    authenticationReason
  })
  this.state.stark.strategy = AuthenticationStrategy.CONSUMER_OTP

  return await authenticate.call(this, payload)
}
