import messenger from '@mc-alberta/messenger'
import { Errors } from '../models/errors.enum'
import remoteLogger from './remoteLogging'

const LOADING = 'LOADING'
const LOADED = 'LOADED'

const iframeState = {
  reference: async () => null,
  loadStatus: null
}

/**
 * Should bootstrap and return the communicator iframe element.
 *
 * @param {string} srcDomain
 * @param {string} nudetectId
 * @returns {Promise<HTMLIFrameElement>}
 */
export default async function iframeReference(srcDomain, nudetectId) {
  let iframePromise, handleIframeLoading

  switch (iframeState.loadStatus) {
    case LOADED:
    case LOADING:
      return iframeState.reference()
    default:
      iframeState.loadStatus = LOADING
      iframePromise = bootstrapIframe(srcDomain, nudetectId)

      handleIframeLoading = async () => {
        const iframe = await iframePromise
        const iframeRefHandler = async () => iframe
        iframeState.reference = iframeRefHandler
        iframeState.loadStatus = LOADED
        return iframe
      }

      iframeState.reference = handleIframeLoading
      return iframeState.reference()
  }
}

/**
 * Initialize the iframe and post back an event once it has been successfully initialized.
 *
 * The iframe is only appended once, but it can be reloaded.
 *
 * @param {string} host
 * @param {string} nudetectId
 * @returns {Promise<HTMLIFrameElement>}
 */
async function bootstrapIframe(host, nudetectId) {
  const el = document.createElement('iframe')

  /**
   * Post back once proxy iframe has successfully initialized.
   *
   * This listener is created prior to iframe creation to reduce risk of race conditions between
   * iframe initialization and the sdk. The drawback of this since it's created prior to iframe
   * creation, is that we cannot validate sender (i.e. the window that sent the message).
   */
  const proxyInitEventListener = messenger.once('mastercard.proxyinit', {
    domain: host
  })

  /**
   * This will be a path on SRC host to allow for non-CORS calls.
   * The `host` is configurable via `init({ host })`.
   *
   * **Note**: All environments must support the same path.
   */
  const version = VERSION
  const communicatorFrameUrl = `${host}/sdk/communicator-frame.${version}.html`

  el.style.border = '0'
  el.style.display = 'none'
  el.style.height = '0'
  el.style.width = '0'
  el.setAttribute('src', communicatorFrameUrl)
  el.setAttribute('allow', 'publickey-credentials-get; publickey-credentials-create')

  document.body.appendChild(el)

  /**
   * Wait for the iframe element to load.
   *
   * **Note**: There's a super edge-case possible if this condition is entered and the iframe
   * becomes available right before the `await` line of code is executed. This may cause the
   * iframe to hang indefinitely.
   *
   * TODO: Determine if this await is necessary since we already have `proxyInitEventListener`.
   * If it is necessary, then we can eliminate the edge-case by improving `forIFrameToLoad` to
   * encompass the `contentWindow` check for an early `resolve()`, or use `Promise.race` if needed.
   */
  if (!el.contentWindow) {
    await forIframeToLoad(el)
  }

  // Wait for the iframe code to initialize.
  await proxyInitEventListener

  try {
    await messenger.send(
      el.contentWindow,
      'mastercard.bootstrap.nudetect',
      { nudetectId },
      { domain: host }
    )
  } catch (error) {
    remoteLogger.error({
      event: Errors.NUDETECT_BOOTSTRAP_ERROR,
      details: {
        browser: navigator?.userAgent,
        cause: error?.cause,
        message: error?.toString()
      }
    })
  }

  iframeState.reference = async () => el
  iframeState.loadStatus = LOADED
  return iframeState.reference()
}

/**
 * Wait for `iframe.contentWindow` to become available.
 *
 * @param {HTMLIFrameElement} iframe
 * @returns {Promise<void>}
 */
export function forIframeToLoad(iframe) {
  return new Promise((resolve) => {
    iframe.addEventListener('load', resolve)
  })
}
