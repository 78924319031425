import {
  type AccountReference,
  type AssuranceData,
  type AuthenticationContext,
  type AuthenticationMethod,
  AuthenticationMethodAttributes,
  AuthenticationReason,
  AuthenticationResult,
  AuthenticationStatus,
  ComplianceSettings,
  type DpaData,
  type DpaTransactionOptions,
  EMVCoVersion
} from '@mc-alberta/types'

export type AuthUiPayload = {
  /** The reference to the cardholder in SRC. */
  accountReference: AccountReference
  /** Contextual data for the authentication session. */
  authenticationContext: AuthenticationContext
  /** SRC initiator identifier. */
  srcClientId: string
  /** Program instance identifier. */
  serviceId: string
  /** The correlation identifier. */
  srcCorrelationId: string
  /** The SRC-I transaction identifier. */
  srciTransactionId?: string
  /** Trace ID */
  traceId?: string
  /** Merchant-defined authentication method. */
  authenticationMethod?: AuthenticationMethod
  /** Portable snapshot of the Stark state to be restored into a new cloned instance. */
  serializedStark?: string
  /** Flag passed in to show AuthUI's "Remember Me" checkbox for MRT */
  requestRecognition?: boolean
}

export enum AuthenticateAction {
  Ready = 'Ready',
  Authenticate = 'Authenticate',
  Success = 'Success',
  Error = 'Error'
}

export type AuthenticateError = {
  name: string
  reason: string
  message?: string
  details?: any
}

export type AuthenticateParams = {
  payload: AuthUiPayload
  baseUrl: string
  windowRef: Window
  resetPromise?: () => void
}

export type AuthenticateRequest = {
  accountReference: AccountReference
  authenticationReasons: AuthenticationReason[]
  complianceSettings?: ComplianceSettings<EMVCoVersion.ONE_POINT_FOUR>
  srcDpaId?: string
  dpaData?: DpaData
  dpaTransactionOptions?: DpaTransactionOptions
  authenticationSessionId?: string
  authenticationMethod?: AuthenticationMethod
  windowRef?: Window | null
  srcCorrelationId?: string
  srcClientId: string
  srciTransactionId?: string
  serviceId?: string
  requestRecognition?: boolean
}

export type AuthenticateSuccess = {
  authenticationResult?: AuthenticationResult
  authenticationStatus?: AuthenticationStatus
  assuranceData?: AssuranceData
  methodAttributes?: AuthenticationMethodAttributes
  srcCorrelationId?: string
  srciTransactionId?: string
  authenticationSessionId?: string
  serializedStark?: string
}

export type AuthenticatePostMessage =
  | {
      action: AuthenticateAction.Success
      payload: AuthenticateSuccess
    }
  | {
      action: AuthenticateAction.Error
      payload: AuthenticateError
    }
  | {
      action: AuthenticateAction.Ready
    }
  | {
      action: AuthenticateAction.Authenticate
      payload: AuthenticateRequest
    }
