import { AuthenticationResult, AuthenticationStatus } from '@mc-alberta/types'
import {
  type AuthUiPayload,
  AuthenticateAction,
  type AuthenticateError,
  type AuthenticateParams,
  type AuthenticatePostMessage,
  type AuthenticateSuccess
} from '../../types/Authenticate'

/**
 * @class
 * @classdesc Authentication service to handle all auth for the SDK.
 * Initiates the headed flow (auth UI) when windowRef is provided
 */
export default class AuthenticateService {
  /** Interval instance for the window-closed watcher. */
  private interval: number

  /** Reference to the auth UI window. */
  private windowRef: Window

  /** Target origin for the window reference. Any other domain's postMessage will be blocked. */
  private origin: string

  /** Post-authentication callback event. */
  private onUnload!: () => void

  /** Post-authentication callback sent from caller. */
  private resetPromise!: () => void

  /**
   * Initiates the authentication experience
   * Launches the auth UI, sets up/tears down event listeners
   *
   * @param authenticationParams - the transformed payload, the baseUrl, and the windowRef
   * @param resetPromise - optional callback to reset promise on complete from caller of authenticate
   */
  authenticate({ payload, baseUrl, windowRef, resetPromise }: AuthenticateParams) {
    return new Promise<AuthenticateSuccess>((resolve, reject) => {
      windowRef.focus()
      this.origin = baseUrl
      this.loadWindow(baseUrl, windowRef)

      const onSuccess = this.handleSuccess.bind(this, resolve)
      const onError = this.handleError.bind(this, reject)
      const onReady = this.handleReady.bind(this, payload)

      if (resetPromise) {
        this.resetPromise = resetPromise
      }

      this.onUnload = () => {
        window.removeEventListener('unload', this.unloadWindow.bind(this))
        window.removeEventListener('message', onReady)
        window.removeEventListener('message', onSuccess)
        window.removeEventListener('message', onError)
      }

      window.addEventListener('unload', this.unloadWindow.bind(this))
      window.addEventListener('message', onReady)
      window.addEventListener('message', onSuccess)
      window.addEventListener('message', onError)

      this.interval = window.setInterval(
        this.watchWindowClose.bind(
          this,
          resolve.bind(this, {
            authenticationStatus: AuthenticationStatus.CANCELLED,
            authenticationResult: AuthenticationResult.NOT_AUTHENTICATED,
            srcCorrelationId: payload.srcCorrelationId,
            srciTransactionId: payload.srciTransactionId
          })
        )
      )
    })
  }

  /**
   * Loads the authentication UI into the window.
   * If the merchant did not provide a windowRef, we will launch a 480x600 popup for them.
   *
   * @param baseUrl - base host for the auth UI application
   * @param windowRef - optional windowRef that the merchant provided
   */
  loadWindow(baseUrl: string, windowRef: Window) {
    const url = `${baseUrl}/auth/?origin=${window.location.origin}`

    this.windowRef = windowRef
    this.windowRef.location.href = url
  }

  /**
   * Closes the auth window.
   */
  unloadWindow(): void {
    // close the window when the page hosting the SDK unloads
    this.onUnload()
    if (this.resetPromise) {
      this.resetPromise()
    }
  }

  /**
   * Watches for the window to be close prematurely (i.e., before the auth UI tells us it's 'Ready')
   * If the user closes the pop AFTER it tells us it's 'Ready', then the UI will be responsible for
   * postMessaging a cancellation event.
   *
   * @param resolve - callback for window closing
   */
  watchWindowClose(resolve: () => void) {
    if (this.windowRef.closed) {
      // the popup closed before auth UI had a chance to load
      clearInterval(this.interval)
      resolve() // resolve with a cancellation event
      this.onUnload() // clean up event listeners
    }
  }

  /**
   * Callback event for when the auth UI sends 'Ready,' an event indicating the UI has loaded.
   * This will provide the UI with the initialization payload.
   *
   * @param payload - payload to send to the auth UI
   * @param message - 'Ready' postMessage received from the UI
   */
  handleReady(
    payload: AuthUiPayload,
    { data, source, origin }: MessageEvent<AuthenticatePostMessage>
  ) {
    if (
      data.action === AuthenticateAction.Ready &&
      origin === this.origin &&
      source === this.windowRef
    ) {
      this.windowRef.postMessage(
        {
          action: AuthenticateAction.Authenticate,
          payload: JSON.parse(JSON.stringify(payload))
        },
        '*'
      )
      clearInterval(this.interval) // auth UI has loaded, no need to keep watching
    }
  }

  /**
   * Callback event for when the auth UI sends 'Success,' an event indicating the user successfully authenticated.
   * This will invoke the given promise resolve method with the payload that the UI provided.
   *
   * @param resolve - promise resolve function
   * @param message - 'Success' postMessage received from the UI
   */
  handleSuccess(
    resolve: (data: AuthenticateSuccess) => void,
    { data, source, origin }: MessageEvent<AuthenticatePostMessage>
  ) {
    if (
      data.action === AuthenticateAction.Success &&
      origin === this.origin &&
      source === this.windowRef
    ) {
      resolve(data.payload)
      this.unloadWindow()
    }
  }

  /**
   * Callback event for when the auth UI sends 'Error,' an event indicating authentication failed.
   * This will invoke the given promise rejection method with the error payload that the UI provided.
   *
   * @param reject - promise rejection function
   * @param message - 'Success' postMessage received from the UI
   */
  handleError(
    reject: (data: AuthenticateError) => void,
    { data, source, origin }: MessageEvent<AuthenticatePostMessage>
  ) {
    if (
      data.action === AuthenticateAction.Error &&
      origin === this.origin &&
      source === this.windowRef
    ) {
      reject(data.payload)
      this.unloadWindow()
    }
  }
}
