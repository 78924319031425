import messenger from '@mc-alberta/messenger'
import { type AuthenticationState, Stark } from '@mc-alberta/stark'
import {
  AuthenticationMethod,
  AuthenticationMethodAttributes,
  AuthenticationMethodType,
  AuthenticationReason,
  AuthenticationResult,
  AuthenticationStatus,
  AuthenticationSubject
} from '@mc-alberta/types'
import { authenticationMethodsLookup } from '../../src/methods/authenticationMethodsLookup'
import SRCError from '../SRCError'
import { DEFAULT_PROGRAM_ID } from '../constants'
import post from '../post'
import AuthenticateService from '../services/authenticate/authenticate.service'
import { IframeService } from '../services/iframe.service'
import {
  type AuthUiPayload,
  type AuthenticateRequest,
  type AuthenticateSuccess
} from '../types/Authenticate'
import type MethodContext from '../types/MethodContext'
import { HttpHeader } from '../types/enums/http-header.enum'

let promise: Promise<AuthenticateSuccess> | null = null

export const modules = { headlessAuthenticate }

export async function authenticate(
  this: MethodContext,
  payload: AuthenticateRequest
): Promise<any> {
  const authenticationState = await invokeAuthenticate.call(this, {
    ...payload,
    authenticationReasons: payload.authenticationReasons?.length
      ? payload.authenticationReasons
      : [AuthenticationReason.CONSUMER_IDENTITY_VALIDATION]
  })

  const methodAttributes =
    authenticationState.methodAttributes as AuthenticationMethodAttributes<AuthenticationMethodType.SMS_OTP>

  return {
    ...authenticationState,
    idToken: authenticationState.assuranceData?.verificationData[0]?.additionalData,
    recognitionToken: methodAttributes?.recognitionToken
  }
}

/**
 * Launches the authentication UI.
 *
 * @param payload - the payload that gets transformed to the expected AuthUIPayload
 */

/* eslint-disable-next-line complexity */ // TODO: reduce complexity
export async function invokeAuthenticate(this: MethodContext, payload: AuthenticateRequest) {
  if (promise) {
    // if auth is already in progress, don't re-launch
    return promise // return the promise already in progress
  }

  const { state } = this
  const authService = new AuthenticateService()

  const srcCorrelationId = payload.srcCorrelationId || state.headers[HttpHeader.SRC_CORRELATION_ID]
  const srciTransactionId =
    payload.srciTransactionId || state.headers[HttpHeader.SRCI_TRANSACTION_ID]

  // headless mode
  if (!payload.windowRef) {
    return decodeAssuranceData(
      modules.headlessAuthenticate.call(this, {
        ...payload,
        srcCorrelationId,
        srciTransactionId
      })
    )
  }

  await authenticationMethodsLookup.call(this, payload)

  IframeService.setPublicKeyPermissionsFor(payload.windowRef)

  // headed mode
  const authUiPayload: AuthUiPayload = {
    accountReference: payload.accountReference,
    authenticationContext: {
      authenticationReasons: payload.authenticationReasons,
      srcDpaId: payload.srcDpaId,
      dpaData: payload.dpaData,
      dpaTransactionOptions: payload.dpaTransactionOptions
    },
    srcClientId: payload.srcClientId,
    serviceId: payload.serviceId || DEFAULT_PROGRAM_ID,
    srcCorrelationId,
    srciTransactionId,
    traceId: state.headers[HttpHeader.X_SRC_TRACE_ID],
    authenticationMethod: {
      authenticationMethodType: AuthenticationMethodType.MANAGED_AUTHENTICATION,
      authenticationSubject: AuthenticationSubject.CONSUMER
    },
    serializedStark: state.stark.serialize(),
    requestRecognition: payload.requestRecognition
  }

  promise = authService.authenticate({
    payload: authUiPayload,
    baseUrl: process.env.MASTERCARD_SRC_HOST!,
    windowRef: payload.windowRef,
    resetPromise
  })
  promise = decodeAssuranceData(promise)

  const { methodAttributes, serializedStark } = await promise
  const stark = Stark.deserialize(serializedStark)
  if (stark) {
    state.stark = stark
  } else {
    return {
      authenticationStatus: AuthenticationStatus.NOT_SUPPORTED,
      authenticationResult: AuthenticationResult.NOT_AUTHENTICATED,
      srcCorrelationId,
      srciTransactionId
    }
  }

  const returnPayload = await decodeAssuranceData(state.stark.getSession())
  return { ...returnPayload, methodAttributes }
}

async function headlessAuthenticate(
  this: MethodContext,
  payload: AuthenticateRequest
): Promise<AuthenticateSuccess> {
  const result = await (() => {
    switch (payload.authenticationMethod?.authenticationMethodType) {
      case AuthenticationMethodType.FIDO2:
        return fidoAuthenticate.call(this, payload)
      default:
        return otpAuthenticate.call(this, payload)
    }
  })()

  this.state.stark.flush()

  return {
    ...result,
    srcCorrelationId: payload.srcCorrelationId,
    srciTransactionId: payload.srciTransactionId
  }
}

async function fidoAuthenticate(
  this: MethodContext,
  payload: AuthenticateRequest
): Promise<AuthenticationState> {
  await authenticationMethodsLookup.call(this, {
    accountReference: payload.accountReference,
    srcCorrelationId: payload.srcCorrelationId,
    srciTransactionId: payload.srciTransactionId
  })

  const serializedStark = this.state.stark.serialize()

  return new Promise((resolve) => {
    setTimeout(async () => {
      try {
        this.state.iframeReference.focus()

        const { data } = await messenger.send<string>(
          this.state.iframeReference.contentWindow,
          'mastercard.authenticate',
          serializedStark,
          { domain: this.state.srcDomain }
        )

        let newStark

        try {
          newStark = Stark.deserialize(data, this.state.stark.payload)
        } catch (e) {
          resolve(
            Promise.resolve({
              authenticationStatus: AuthenticationStatus.NOT_SUPPORTED,
              authenticationResult: AuthenticationResult.NOT_AUTHENTICATED
            } as AuthenticationState)
          )
        }

        if (newStark) {
          this.state.stark = newStark
        }

        resolve(this.state.stark.getSession())
      } catch (e) {
        resolve(
          Promise.resolve({
            authenticationStatus: AuthenticationStatus.NOT_SUPPORTED,
            authenticationResult: AuthenticationResult.NOT_AUTHENTICATED
          } as AuthenticationState)
        )
      }
    }, 50)
  })
}

async function otpAuthenticate(
  this: MethodContext,
  payload: AuthenticateRequest
): Promise<AuthenticationState> {
  const stark = this.state.stark
  const authenticationMethod = payload.authenticationMethod as
    | AuthenticationMethod<AuthenticationMethodType.SMS_OTP | AuthenticationMethodType.EMAIL_OTP>
    | undefined

  if (authenticationMethod?.methodAttributes?.otpValue) {
    const nudetectWidgetData = await post.getNudetectData(this.state)

    this.state.stark.setMethodAttributes({
      complianceSettings: payload.complianceSettings,
      nudetectWidgetData
    })

    await stark.provideChallenge(authenticationMethod.methodAttributes.otpValue)
    const authenticateState = await stark.validateSession()
    if (authenticateState.authenticationStatus === AuthenticationStatus.CHALLENGE_FAILED) {
      throw new SRCError({
        error: { status: 403, reason: 'ACCT_INACCESSIBLE', message: 'Account inaccessible' }
      })
    }

    return authenticateState
  }

  const otpAuthenticationState: AuthenticationState = await stark.initializeSession({
    authenticationSubject: AuthenticationSubject.CONSUMER,
    authenticationReason: payload.authenticationReasons[0],
    authenticationMethod: payload.authenticationMethod,
    authorization: this.state.idLookupSessionId
  })

  return otpAuthenticationState
}

function resetPromise(): void {
  promise = null
}

/**
 * Returns given authentication state with its `additionalData` base64-decoded (if present).
 */
async function decodeAssuranceData<T extends AuthenticateSuccess | AuthenticationState>(
  promise: Promise<T>
): Promise<T> {
  try {
    const state = await promise

    return {
      ...state,
      assuranceData: state.assuranceData && {
        ...state.assuranceData,
        verificationData: state.assuranceData.verificationData.map((verificationData) => ({
          ...verificationData,
          additionalData: (() => {
            try {
              return atob(verificationData.additionalData || '')
            } catch (_) {
              return verificationData.additionalData
            }
          })()
        }))
      }
    }
  } catch (_) {
    return promise
  }
}
