import State from '../../types/State'

export const generateDefaultSDKState = (): Partial<State> => ({
  // cache between calls:

  // lumber traceId
  traceId: undefined,

  // client id/initiator id
  srcInitiatorId: undefined,

  // axios headers for correlation id, client id, etc
  headers: {},

  // init
  dpaTransactionOptions: undefined, // merchant options
  dpaData: undefined,
  isMastercardSrci: false
})

export const generateDefaultConsumerState = (): Partial<State> => ({
  // profile
  profiles: [], // profiles returned by getSrcProfile()
  recognizedIdToken: undefined, // idTokens from isRecognized()

  // identity
  idLookupSessionId: undefined, // internal state during idlookup/verification

  // enroll
  cardToken: undefined, // anon card token returned by enroll()
  maskedCards: [] // maskedCards returned by enroll()
})
