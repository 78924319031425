import { AuthenticationStrategy } from '@mc-alberta/stark'
import {
  AuthenticationMethodType,
  AuthenticationReason,
  AuthenticationSubject
} from '@mc-alberta/types'
import {
  AuthenticateMethodLookupParams,
  AuthenticateMethodLookupResult
} from '../types/AuthenticationMethodsLookup'
import MethodContext from '../types/MethodContext'
import utils from '../utils'
import validate from '../validate'
import { identityLookup } from './identity'

export async function authenticationMethodsLookup(
  this: MethodContext,
  { accountReference, srcCorrelationId, srciTransactionId }: AuthenticateMethodLookupParams
): Promise<AuthenticateMethodLookupResult> {
  const { state } = this

  utils.performNonBlockingValidations(
    {
      // eslint-disable-next-line prefer-rest-params
      params: arguments[0],
      methodName: 'authenticationMethodsLookup'
    },
    state
  )

  if (accountReference?.consumerIdentity) {
    const { consumerIdentity } = accountReference

    validate.params({ consumerIdentity })
    validate.identity({ consumerIdentity })
  }

  if (!state.idLookupSessionId) {
    // identityLookup will produce an idLookupSessionId that is added to the state
    await identityLookup.call({ state }, { consumerIdentity: accountReference.consumerIdentity })
  }

  if (!state.idLookupSessionId) {
    return {
      authenticationMethods: [],
      srcCorrelationId,
      srciTransactionId
    }
  }

  const currentInstance = state.stark.getAuthenticationInstance({
    authenticationSubject: AuthenticationSubject.CONSUMER
  })

  const newInstance = {
    accountReference,
    authenticationStrategies: {
      [AuthenticationReason.CONSUMER_IDENTITY_VALIDATION]: [
        AuthenticationStrategy.FIDO2,
        AuthenticationStrategy.CONSUMER_OTP
      ]
    },
    idLookupSessionId: state.idLookupSessionId,
    authenticationSubject: AuthenticationSubject.CONSUMER
  }

  if (currentInstance?.accountReference) {
    state.stark.updateAuthenticationInstance({
      ...currentInstance,
      ...newInstance
    })
  } else {
    state.stark.setAuthenticationInstance(newInstance)
  }

  await state.stark.loadAuthenticationMethods()

  const authenticationMethodsFido = state.stark
    .getAuthenticationMethods(
      { authenticationSubject: AuthenticationSubject.CONSUMER },
      AuthenticationStrategy.FIDO2
    )
    .filter((method) => {
      return (
        method.authenticationMethodType !== AuthenticationMethodType.FIDO2 ||
        method.methodAttributes?.boundMfaMethod
      )
    })

  const authenticationMethodsOtp = state.stark.getAuthenticationMethods(
    { authenticationSubject: AuthenticationSubject.CONSUMER },
    AuthenticationStrategy.CONSUMER_OTP
  )

  return {
    authenticationMethods: [...authenticationMethodsFido, ...authenticationMethodsOtp],
    srcCorrelationId,
    srciTransactionId
  }
}
