// RecognitionStatus
export const UNKNOWN = 'UNKNOWN'
export const REMEMBERED = 'REMEMBERED'

export const sanctionedCountries = [
  'CU', // Cuba
  'IR', // Iran
  'KP', // North Korea
  'SD', // Sudan
  'SY', // Syria
  'RU' // Russia
]

export const dpaLocaleValidationMessages = {
  invalidFormat: 'dpaLocale format is invalid',
  sanctionedCountryPrefix: 'dpaLocale is invalid due to sanctioned countryCode'
}

export const mastercardSrcInitiatorIds = {
  stage: '2fb2cd53-a454-4818-9e65-e572240db922',
  'bel-prod': '78fbc211-73e1-4c3a-bc5c-60a7921afb97',
  'kick-one': '526e1d31-1feb-4026-abcf-ca6ce85ee9cb',
  'kick-two': '526e1d31-1feb-4026-abcf-ca6ce85ee9cb',
  'ksc-prod': '78fbc211-73e1-4c3a-bc5c-60a7921afb97',
  perf: '526e1d31-1feb-4026-abcf-ca6ce85ee9cb',
  'perf-soak': '526e1d31-1feb-4026-abcf-ca6ce85ee9cb',
  'perf-stress': '526e1d31-1feb-4026-abcf-ca6ce85ee9cb',
  'pre-prod': '544ef81a-dae0-4f26-9511-bfbdba3d62b5',
  'src-int': '526e1d31-1feb-4026-abcf-ca6ce85ee9cb',
  'src-itf': '198adcff-4d16-46a2-a536-2d0a2fcde1a2',
  'src-mtf': '544ef81a-dae0-4f26-9511-bfbdba3d62b5',
  'src-prod': '78fbc211-73e1-4c3a-bc5c-60a7921afb97',
  'src-uat': '526e1d31-1feb-4026-abcf-ca6ce85ee9cb',
  local: '123456-789101'
}

export const featureFlags = {
  SDK_NUDETECT_ENABLED: 'SDK_NUDETECT_ENABLED'
}

export const nudetectWidgetHeaderName = 'X-SRC-NUDETECT-WIDGETDATA'
export const nudetectWidgetDataKeyName = 'nudetectWidgetData'

// Standard codes apply to all API methods
const standardEmvcoErrorReasonCodes = [
  'UNKNOWN_ERROR',
  'REQUEST_TIMEOUT',
  'INVALID_PARAMETER',
  'INVALID_REQUEST',
  'AUTH_ERROR',
  'NOT_FOUND',
  'SERVICE_ERROR',
  'SERVER_ERROR'
]

const initEmvcoErrorReasonCodes = [
  'SRCI_ID_MISSING',
  'DPA_ID_MISSING',
  'SRCI_TXID_MISSING',
  'DPA_TXOPT_MISSING',
  ...standardEmvcoErrorReasonCodes
]

const isRecognizedEmvcoErrorReasonCodes = ['ACCT_INACCESSIBLE', ...standardEmvcoErrorReasonCodes]

const getSrcProfileEmvcoErrorReasonCodes = [
  'ACCT_INACCESSIBLE',
  'AUTH_INVALID',
  ...standardEmvcoErrorReasonCodes
]

const identityLookupEmvcoErrorReasonCodes = [
  'CONSUMER_ID_MISSING',
  'ID_FORMAT_UNSUPPORTED',
  'ACCT_INACCESSIBLE',
  'FRAUD',
  ...standardEmvcoErrorReasonCodes
]

const initiateIdentityValidationEmvcoErrorReasonCodes = [
  'OTP_SEND_FAILED',
  'RETRIES_EXCEEDED',
  'ACCT_INACCESSIBLE',
  'SESSION_ID_INVALID',
  ...standardEmvcoErrorReasonCodes
]

const completeIdentityValidationEmvcoErrorReasonCodes = [
  'VALDATA_MISSING',
  'CODE_INVALID',
  'CODE_EXPIRED',
  'RETRIES_EXCEEDED',
  'ACCT_INACCESSIBLE',
  ...standardEmvcoErrorReasonCodes
]

const enrollCardEmvcoErrorReasonCodes = [
  'CARD_MISSING',
  'CARD_ADD_FAILED',
  'CARD_SECURITY_CODE_MISSING',
  'CARD_INVALID',
  'CARD_EXP_INVALID',
  'AUTH_INVALID',
  'ACCT_INACCESSIBLE',
  ...standardEmvcoErrorReasonCodes
]

export const checkoutEmvcoErrorReasonCodes = [
  'CARD_MISSING',
  'CARD_ADD_FAILED',
  'CARD_SECURITY_CODE_MISSING',
  'CARD_INVALID',
  'CARD_NOT_RECOGNIZED',
  'CARD_EXP_INVALID',
  'MERCHANT_DATA_INVALID',
  'UNABLE_TO_CONNECT',
  'AUTH_INVALID',
  'TERMS_AND_CONDITIONS_NOT_ACCEPTED',
  'ACCT_INACCESSIBLE',
  ...standardEmvcoErrorReasonCodes
]

const deleteCardEmvcoErrorReasonCodes = [
  'CARDID_MISSING',
  'CARD_NOT_RECOGNIZED',
  'AUTH_INVALID',
  'ACCT_INACCESSIBLE',
  ...standardEmvcoErrorReasonCodes
]

const unbindAppInstanceEmvcoErrorReasonCodes = [
  'AUTH_INVALID',
  'ACCT_INACCESSIBLE',
  ...standardEmvcoErrorReasonCodes
]

const authenticationMethodsLookupEmvcoErrorReasonCode = [
  'ACCT_REF_FORMAT_UNSUPPORTED',
  'ACCT_REF_MISSING',
  ...standardEmvcoErrorReasonCodes
]

// standardEmvcoErrorReasonCodes excluded since they're included
// in every collection
export const allEmvcoErrorReasonCodes = [
  ...new Set([
    ...initEmvcoErrorReasonCodes,
    ...isRecognizedEmvcoErrorReasonCodes,
    ...getSrcProfileEmvcoErrorReasonCodes,
    ...identityLookupEmvcoErrorReasonCodes,
    ...initiateIdentityValidationEmvcoErrorReasonCodes,
    ...completeIdentityValidationEmvcoErrorReasonCodes,
    ...enrollCardEmvcoErrorReasonCodes,
    ...checkoutEmvcoErrorReasonCodes,
    ...deleteCardEmvcoErrorReasonCodes,
    ...unbindAppInstanceEmvcoErrorReasonCodes,
    ...authenticationMethodsLookupEmvcoErrorReasonCode
  ])
]

export const WINDOW_PARAMS = ['about:blank', 'dcf', 'width=480,height=600']

// Mastercard DCF name
export const MASTERCARD = 'mastercard'

// Mastercard DCF postMessage events
export const DCF_READY = 'READY'
export const DCF_ACTION = 'ACTION'

// Third party wallets postMessage events
export const TPW_DCF_READY = 'TPW_DCF_READY'
export const TPW_DCF_ACTION = 'TPW_DCF_ACTION'
