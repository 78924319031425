export class IframeService {
  static setPublicKeyPermissionsFor(windowRef: Window) {
    const iframes = document.querySelectorAll('iframe')

    iframes.forEach((iframe) => {
      if (iframe.contentWindow === windowRef) {
        iframe.setAttribute('allow', 'publickey-credentials-get *')
      }
    })
  }
}
