// from MC SRCI SDK API spec "EMVCo SRC Web Client SDK 1.0 (TA Review)", Mar 2019
import DecorateSDKWithFallbackError from '../helpers/DecorateSDKWithFallbackError'
import DecorateWithKPILogging from '../helpers/DecorateWithKPILogging'
import DecorateWithSDKInitCheck from '../helpers/DecorateWithSDKInitCheck'
import { authenticate } from './authenticate'
import { authenticationMethodsLookup } from './authenticationMethodsLookup'
import { checkout } from './checkout'
import { deleteCard, unbindAppInstance } from './delete'
import enrollCard from './enroll'
import { completeIdentityValidation, identityLookup, initiateIdentityValidation } from './identity'
import init from './init'
import { getSrcProfile, isRecognized } from './profile'

// NOTE: We should refactor all wrappers into one executor function, instead of adding a 4th wrapper
export default {
  ...DecorateWithKPILogging({
    ...DecorateSDKWithFallbackError({
      init,
      ...DecorateWithSDKInitCheck({
        isRecognized,
        getSrcProfile,
        identityLookup,
        initiateIdentityValidation,
        completeIdentityValidation,
        enrollCard,
        checkout,
        deleteCard,
        unbindAppInstance,
        authenticate,
        authenticationMethodsLookup
      })
    })
  })
}
