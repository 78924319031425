import { lumber } from '@mc-alberta/lumber'
import { AuthenticationSubject } from '@mc-alberta/types'
import SRCError from '../SRCError'
import post from '../post'
import utils from '../utils'
import validate from '../validate'

// get the correct idToken from the profile for the requested card id
function findToken(srcDigitalCardId) {
  const { state } = this
  let idToken
  state.profiles.forEach((profile) => {
    if (profile.maskedCards.find((card) => card.srcDigitalCardId === srcDigitalCardId)) {
      idToken = profile.authorization
    }
  })
  return idToken
}

export async function deleteCard(params) {
  const { state } = this

  // ensure params are wrapped in an object
  params = utils.paramsIsAnObject(params) ? params : {}
  const { srcDigitalCardId } = params

  utils.performNonBlockingValidations(
    {
      params,
      methodName: 'deleteCard'
    },
    state
  )

  validate.params({ srcDigitalCardId })

  const message = {
    method: 'delete',
    url: `cards/${srcDigitalCardId}`,
    headers: { ...state.headers },
    data: { srcDigitalCardId }
  }

  const idToken = findToken.call(this, srcDigitalCardId)
  if (idToken) {
    message.headers.Authorization = `Bearer ${idToken}`
  } else {
    throw new SRCError({
      error: { status: 400, reason: 'CARD_NOT_RECOGNIZED', message: 'Card not recognized' }
    })
  }

  const result = await post.send(message, state)
  validate.response(result, {
    400: 'INVALID_PARAMETER',
    404: 'CARD_NOT_RECOGNIZED'
  })

  lumber.flushQueue()

  return {
    srcCorrelationId: state.headers['SRC-Correlation-Id'] // required
  }
}

export async function unbindAppInstance(params) {
  const { state } = this
  // ensure params are wrapped in an object
  params = utils.paramsIsAnObject(params) ? params : {}

  utils.performNonBlockingValidations(
    {
      params,
      methodName: 'unbindAppInstance'
    },
    state
  )

  const message = {
    method: 'delete',
    url: 'appInstances',
    headers: {
      ...state.headers,
      ...(params.recognitionToken && { 'SRC-Recognition-Token': params.recognitionToken })
    }
  }
  const result = await post.send(message, state)

  /*
    There are two API error responses for which unbindAppInstance
    should resolve:

    1. No cookie is present API response
      {
        status: 400,
        data: {
          code: 400,
          status: 'INVALID_STATE',
          message:
            'Request cannot be executed due to incorrect field value.',
          details: null
        }
      }
    2. Cookie not found (i.e. A cookies was sent but it was already invalidated via AMS)
      {
        status: 404,
        data: {
          code: null,
          status: null,
          message: null,
          details: null
        }
      }
    This is a temporary fix until the API returns a more specific error response.

    TODO: Follow-up with Marco Talabaco to verify state of API responses for
    DELETE api/appInstances

    shouldNotSuppressApiErrorResponse will verify if the API response
    matches one of the two cases above. If so it will return false to skip
    the validate.response function call.
  */
  if (shouldNotSuppressApiErrorResponse(result)) {
    // Validate.response will throw an SRC Error in the event
    // of an API error
    validate.response(result, {
      400: 'INVALID_PARAMETER'
    })
  }

  state.stark.removeAuthenticationInstance({
    authenticationSubject: AuthenticationSubject.CONSUMER
  })

  lumber.flushQueue()

  state.resetConsumerState()

  return {
    srcCorrelationId: state.headers['SRC-Correlation-Id']
  }
}

function shouldNotSuppressApiErrorResponse(apiResponse) {
  const noValidCookieToUnbindResponse = {
    httpStatus: 400,
    code: 400,
    dataStatus: 'INVALID_STATE',
    message: 'Request cannot be executed due to incorrect field value.',
    details: null
  }

  const cookieIsNotFound = {
    httpStatus: 404,
    code: null,
    dataStatus: null,
    message: null,
    details: null
  }

  function apiResponseMatchesErrorDescription(apiResponse, errorDescription) {
    try {
      const {
        errors: [
          {
            status: httpStatus,
            data: { code, status: dataStatus, message, details }
          }
        ]
      } = apiResponse

      const apiResponseData = {
        httpStatus,
        code,
        dataStatus,
        message,
        details
      }

      // if any comparison failed return false
      return !Object.keys(errorDescription)
        .map((keyName) => errorDescription[keyName] === apiResponseData[keyName])
        // Did any comparison fail
        .includes(false)
    } catch (e) {
      // Destructure failed. API response does not match expected error format
      return false
    }
  }

  const errorsToSuppress = [cookieIsNotFound, noValidCookieToUnbindResponse]

  // If the API response matched any of the errorsToSuppress return false
  return !errorsToSuppress
    .map((errorDescription) => apiResponseMatchesErrorDescription(apiResponse, errorDescription))
    .includes(true)
}
