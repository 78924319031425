export default {
  renameProperty,
  cloneDeep,
  mapComplianceSettings,
  reMapComplianceSettings,
  transformDynamicDataType
}

// convert a property on an object from an old name to a new name
function renameProperty(obj, oldProp, newProp) {
  if (typeof obj === 'undefined') {
    return obj
  }
  if (oldProp === newProp) {
    return obj
  }
  if (!(oldProp in obj)) {
    return obj
  }

  obj[newProp] = obj[oldProp]
  delete obj[oldProp]

  return obj
}

function cloneDeep(data) {
  if (typeof data === 'undefined') {
    return undefined
  }
  return JSON.parse(JSON.stringify(data))
}

// convert a flat compliance settings to the newer nested structure
function mapComplianceSettings(request) {
  const response = {}
  const settings = ['privacy', 'tnc', 'cookie']

  settings.forEach((setting) => {
    // settingLatestVersion is required, all values are strings
    const latestVersion = request[setting + 'LatestVersion']
    if (latestVersion) {
      const latestVersionUri = request[setting + 'LatestVersionUri']
      const acceptedVersion = request[setting + 'AcceptedVersion']
      response[setting] = {
        latestVersion,
        latestVersionUri,
        acceptedVersion
      }
    }
  })

  if (request.marketingOptIn === true || request.marketingOptIn === false) {
    response.communications = {
      communicationsOptIn: request.marketingOptIn
    }
  }

  return response
}

function reMapComplianceSettings(data) {
  return Object.keys(data).reduce((remappedComplianceSettings, category) => {
    // settings categories to flatten
    const categoriesToRemap = ['privacy', 'tnc', 'cookie']
    // NOTE: geoLocation is not possible, did not exist in 0.9

    // flatten categories setting
    if (categoriesToRemap.includes(category)) {
      Object.keys(data[category]).forEach((settingName) => {
        const remappedNewSettingName = settingName[0].toUpperCase() + settingName.slice(1)
        remappedComplianceSettings[`${category}${remappedNewSettingName}`] =
          data[category][settingName]
      })
    }

    // add marketingOptIn to the root object if present
    if (category === 'communications') {
      remappedComplianceSettings.marketingOptIn = data[category].communicationsOptIn
    }

    return remappedComplianceSettings
  }, {})
}

function transformDynamicDataType(response) {
  const dynamicDataTypeMappings = {
    DYNAMIC_CARD_SECURITY_CODE: 'CARD_SECURITY_CODE',
    /*
      CARD_APPLICATION_CRYPTOGRAM_LONG_FORM (ICC) to CARD_APPLICATION_CRYPTOGRAM
      CARD_APPLICATION_CRYPTOGRAM_LONG_FORM is introduced in the EMVCO 1.0 API spec (the final version)
      the value is not present in the TA edition. Transform is added to align with the options listed
      the MC developer portal
    */
    CARD_APPLICATION_CRYPTOGRAM_LONG_FORM: 'CARD_APPLICATION_CRYPTOGRAM',
    /*
      CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM (UCAF) to CARDHOLDER_AUTHENTICATION_CRYPTOGRAM
      CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM is introduced in the EMVCO 1.0 API spec (the final version)
      the value is not present in the TA edition. Transform is added to align with the options listed
      the MC developer portal
    */
    CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM: 'CARDHOLDER_AUTHENTICATION_CRYPTOGRAM'
  }

  response.paymentOptions.dynamicDataType =
    dynamicDataTypeMappings[response.paymentOptions.dynamicDataType] ||
    response.paymentOptions.dynamicDataType
}
