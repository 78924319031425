import axios, { type AxiosError } from 'axios'

/**
 * See if the error has a valid error structure or if it's malformed.
 *
 * This helps makes TS happy and also helps us know if something went fatally wrong.
 */
export function isValidError(error: unknown): error is Error | AxiosError {
  return error instanceof Error || axios.isAxiosError(error)
}
