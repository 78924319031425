import { AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import post from '../post'
import State from '../types/State'
import type { ErrorResponse, FakeAxiosResponse } from './axiosAdapter.types'

/**
 * Creats a mimic of axios that instead uses our internal post messaging
 * to the proxy iframe to make the actual XHR to API. Currently instantiated and
 * passed to stark in the init method so any stark XHR requests throughout the sdk are
 * done through the iframe.
 *
 * @param state - current sdk state
 */
export default function createAxios(state: State) {
  return async <T, R = FakeAxiosResponse<T>, D = any>(
    params: AxiosRequestConfig<D>
  ): Promise<FakeAxiosResponse<R>> => {
    const response: FakeAxiosResponse<R> | ErrorResponse = await post.request<R>(params, state)

    if ('errors' in response) {
      const error = response.errors[0]
      const config = params as InternalAxiosRequestConfig

      return Promise.reject(
        new AxiosError('error message', error.status.toString(), config, params, {
          ...error,
          headers: {},
          config,
          statusText: 'status'
        })
      )
    }

    return response
  }
}
