import licit from '@mc-alberta/licit'
import jwtDecode from 'jwt-decode'
import { DEFAULT_PROGRAM_ID } from '../constants'
import utils from '../utils'
import { ThreeDsPreferenceEnum } from '../validations/rules/emvco/enums'
import helpers from './helpers'

// transform objects to/from API calls
//
// SDK must use APIs built to EMVCO 0.9-ish specs
// but must expose/consume EMVCO 1.0 objects to SRCI
//
// Analysis: https://fusion.mastercard.int/confluence/x/jw7wF
// and https://fusion.mastercard.int/confluence/x/2xrwF

// x.request  - converts object to 0.9 format (for API and DCF)
// x.response - converts object to 1.0 format (for SRCI)
const transform = {
  profile: {
    // convert profiles returned by getSrcProfile to 1.0 format
    response(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'cards', 'maskedCards')
      helpers.renameProperty(response, 'consumer', 'maskedConsumer')
      helpers.renameProperty(response, 'shippingAddresses', 'maskedShippingAddresses')

      // authorization is a required string in 1.0, was an array in 0.9
      helpers.renameProperty(response, 'consumerAuthorisation', 'authorization')
      // with getSrcProfile, incoming federated tokens will be exchanged for
      // MC tokens on the profile, but there will still only be one
      if (Array.isArray(response.authorization)) {
        response.authorization = response.authorization[0]
      }

      delete response.consumerAuthorisation

      if (response.maskedConsumer) {
        response.maskedConsumer = transform.consumer.response(response.maskedConsumer)
      }

      const cards = response.maskedCards
      if (cards) {
        cards.forEach((card, index) => {
          cards[index] = transform.card.response(card)
        })
      }

      const addresses = response.maskedShippingAddresses
      if (addresses) {
        addresses.forEach((address, index) => {
          addresses[index] = transform.shippingAddress.response(address)
        })
      }

      response.programId = response.programId || DEFAULT_PROGRAM_ID

      return response
    }
  },

  consumer: {
    request(data) {
      const response = helpers.cloneDeep(data)

      if (response.complianceSettings) {
        response.complianceSettings = helpers.reMapComplianceSettings(response.complianceSettings)
      }

      if (response.maskedConsumerIdentity) {
        response.maskedConsumerIdentity = transform.identity.consumerIdentity.request(
          response.maskedConsumerIdentity
        )
      }
      return response
    },
    response(data) {
      const response = helpers.cloneDeep(data)

      if (response.complianceSettings) {
        response.complianceSettings = helpers.mapComplianceSettings(response.complianceSettings)
      }
      if (response.maskedConsumerIdentity) {
        response.maskedConsumerIdentity = transform.identity.consumerIdentity.response(
          response.maskedConsumerIdentity
        )
      }

      return response
    }
  },

  identity: {
    consumerIdentity: {
      request(data) {
        const request = { ...data }
        if (request.identityType === 'EMAIL_ADDRESS') {
          request.identityType = 'EMAIL'
        }
        if (request.identityType === 'MOBILE_PHONE_NUMBER') {
          request.identityType = 'SMS'
        }
        if (!request.identityProvider) {
          request.identityProvider = 'SRC'
        }
        return request
      },
      response(data) {
        const request = { ...data }
        if (request.identityType === 'EMAIL') {
          request.identityType = 'EMAIL_ADDRESS'
        }
        if (request.identityType === 'SMS') {
          request.identityType = 'MOBILE_PHONE_NUMBER'
        }
        return request
      }
    }
  },

  card: {
    // eslint-disable-next-line complexity
    request(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'tokenBinRange', 'tokenBin')
      helpers.renameProperty(response, 'maskedBillingAddress', 'billingAddress')
      if (response.panExpirationYear && response.panExpirationYear.length === 4) {
        response.panExpirationYear = response.panExpirationYear.slice(-2)
      }

      if (response.dcf && response.dcf.applicationType) {
        helpers.renameProperty(response.dcf, 'applicationType', 'type')
      }

      // 0.9 has this value duplicated.  copy it if we've deleted it
      if (
        response.digitalCardData &&
        response.digitalCardData.status &&
        !response.digitalCardStatus
      ) {
        response.digitalCardStatus = response.digitalCardData.status
      }

      return response
    },

    response(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'tokenBin', 'tokenBinRange')
      helpers.renameProperty(response, 'billingAddress', 'maskedBillingAddress')
      if (response.maskedBillingAddress) {
        response.maskedBillingAddress = transform.billingAddress.response(
          response.maskedBillingAddress
        )
      }
      if (response.panExpirationYear && response.panExpirationYear.length === 2) {
        response.panExpirationYear = '20' + response.panExpirationYear
      }

      if (response.digitalCardStatus) {
        response.digitalCardData = {
          ...response.digitalCardData,
          status: response.digitalCardStatus
        }
        delete response.digitalCardStatus
      }
      // NOTE: "final" 1.0 spec will need .dcf.type -> .dcf.applicationType

      return response
    }
  },

  shippingAddress: {
    request(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'addressId', 'shippingAddressId')
      // TODO: this may not work, is this code or name?
      if (response.countryCode) {
        response.country = response.countryCode
      }

      return response
    },
    response(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'shippingAddressId', 'addressId')
      delete response.country // in 1.0 we only have countryCode

      return response
    }
  },

  billingAddress: {
    request(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'addressId', 'billingAddressId')
      // TODO: this may not work, is this code or name?
      if (response.countryCode) {
        response.country = response.countryCode
      }

      return response
    },
    response(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'billingAddressId', 'addressId')
      delete response.country

      return response
    }
  },

  // 'legacy' support - transform 1.0 DPA to 0.9 DSA for DCF
  // NOTE: SDK *does not* accept 0.9 format and convert to 1.0
  dpaTransactionOptions: {
    request({ payloadTypeIndicatorCheckout, ...data }) {
      const response = helpers.cloneDeep(data)
      const dpaTransactionOptionsMap = {
        dpaBillingPreference: 'dsaBillingPreference',
        dpaAcceptedBillingCountries: 'dsaAcceptedBillingCountries',
        dpaShippingPreference: 'dsaShippingPreference',
        dpaAcceptedShippingCountries: 'dsaAcceptedShippingCountries'
      }
      Object.keys(dpaTransactionOptionsMap).forEach((key) => {
        helpers.renameProperty(response, key, dpaTransactionOptionsMap[key])
      })

      if (payloadTypeIndicatorCheckout) {
        response.payloadTypeIndicator = payloadTypeIndicatorCheckout
      }

      if (response.threeDsInputData) {
        const data = [
          { key: 'requestorId', value: response.threeDsInputData.requestorId },
          { key: 'acquirerId', value: response.threeDsInputData.acquirerId },
          { key: 'acquirerMid', value: response.threeDsInputData.acquirerMid }
        ]
        response.threeDsInputData = data
        delete response.threeDsPreference
      }

      if (response.paymentOptions) {
        if (response.paymentOptions.dynamicDataType) {
          helpers.transformDynamicDataType(response)
        }
        helpers.renameProperty(
          response.paymentOptions,
          'dpaDynamicDataTtlMinutes',
          'dsaDynamicDataTtlMinutes'
        )
        delete response.paymentOptions.dsaCredentialRequested
        delete response.paymentOptions.dsaReceivingProgramIdentifier
      }

      const attributes = [
        'recipientKeyIdPayload',
        'recipientKeyIdCheckout',
        'payloadTypeIndicatorPayload',
        'payloadTypeIndicatorCheckout'
      ]
      const customAttributes = []
      attributes.forEach((attribute) => {
        if (response[attribute]) {
          customAttributes.push({ key: attribute, value: response[attribute] })
          delete response[attribute]
        }
      })
      if (customAttributes.length) {
        response.dsaCustomAttributes = customAttributes
      }

      delete response.isGuestCheckout
      delete response.networkSpecificInputData
      delete response.serviceId
      delete response.transactionType
      delete response.dpaLocale

      /**
       * Temporarily strip until contract is defined and communicated to SRCis.
       * No changes are required to DCF postMessage contract to avoid impact to existing BAU flows.
       */
      delete response.srcTokenRequestData

      return response
    }
  },
  // Returns merchantCountryCode
  // TODO: this can be remove and use only one transform after make sure mastercard DCF accepts this updates
  // This is need it for consumerProfiles and TPW checkout
  merchantCountryCode: {
    request({ ...data }) {
      const response = helpers.cloneDeep(data)
      if (!response.merchantCountryCode && response.dpaLocale) {
        response.merchantCountryCode = utils.getCountryCode(response.dpaLocale)
      }

      return response
    }
  },

  // Returns transactionOptions with dsaLocale (0.9 format)
  dpaLocale: {
    request(data) {
      const response = helpers.cloneDeep(data)

      helpers.renameProperty(response, 'dpaLocale', 'dsaLocale') // 0.9 format

      return response
    }
  },

  // convert 1.0 dpaData to 0.9 dsaData for DCF
  dpaData: {
    // need dpaTransactionOptions to get threeDsPreference
    request(data = {}, dpaTransactionOptions) {
      const response = helpers.cloneDeep(data)
      const dpaDataMap = {
        dpaPresentationName: 'dsaPresentationName',
        dpaAddress: 'dsaAddress',
        dpaName: 'dsaName',
        dpaEmailAddress: 'dsaEmailAddress',
        dpaPhoneNumber: 'dsaPhoneNumber',
        dpaLogoUri: 'dsaLogoUri',
        dpaSupportEmailAddress: 'dsaSupportEmailAddress',
        dpaSupportPhoneNumber: 'dsaSupportPhoneNumber',
        dpaSupportUri: 'dsaSupportUri',
        dpaUri: 'dsaUri'
      }

      Object.keys(dpaDataMap).forEach((key) => {
        helpers.renameProperty(response, key, dpaDataMap[key])
      })

      const threeDsPreferenceDefaultValue = 'NONE'
      const threeDsPreference =
        licit.isAnObject(dpaTransactionOptions) &&
        ThreeDsPreferenceEnum.includes(dpaTransactionOptions.threeDsPreference) &&
        dpaTransactionOptions.threeDsPreference

      response.dsaThreeDsPreference = threeDsPreference || threeDsPreferenceDefaultValue

      function transformPhone(phone) {
        if (phone && phone.countryCode && phone.phoneNumber) {
          phone = `${phone.countryCode}${phone.phoneNumber}`
        }
        return phone
      }

      if (response.dsaSupportPhoneNumber) {
        response.dsaSupportPhoneNumber = transformPhone(response.dsaSupportPhoneNumber)
      }

      if (response.dsaPhoneNumber) {
        response.dsaPhoneNumber = transformPhone(response.dsaPhoneNumber)
      }

      // TODO: convert dsaAddress obj -> Str
      return response
    }
  },
  checkout: {
    // handle the DCF response and resolve payload
    response(result) {
      let response = {
        dcfActionCode: result.action,
        unbindAppInstance: !!result.unbindAppInstance
      }
      if (result.action === 'COMPLETE') {
        const { checkoutResponseJWS: checkoutResponseSignature } = result

        response = {
          ...response,
          checkoutResponseSignature,
          // checkoutResponse follows a EMVCO 1.0 data dictionary
          // no transformation should be applied to checkoutResponse
          // to avoid invalidating the signature (checkoutResponseSignature)
          checkoutResponse: jwtDecode(checkoutResponseSignature)
        }
      }

      if (result.idToken) {
        response.idToken = result.idToken
      } // optional
      if (result.recognitionToken) {
        response.recognitionToken = result.recognitionToken
      } // optional

      return response
    }
  }
}

export default transform
