import messenger from '@mc-alberta/messenger'
import { Stark } from '@mc-alberta/stark'
import type { AxiosRequestConfig } from 'axios'
import events from '../events.json'
import SRCError from './SRCError'
import remoteLogger from './helpers/remoteLogging'
import { Errors } from './models/errors.enum'
import type { FakeAxiosResponseOrError } from './post.types'
import type State from './types/State'
import { HttpHeader } from './types/enums/http-header.enum'
import type { ErrorResponse, FakeAxiosResponse } from './utils/axiosAdapter.types'
import { isValidError } from './utils/is-valid-error'

let windowRef: Window, domain: string

// cache SRC-Correlation-Id and SRC-Recognition-Token to headers
// so we can feed back to API request
/* eslint-disable-next-line complexity */ // TODO: Reduce complexity
function cacheHeaders(response: FakeAxiosResponse, state: Partial<State> = { headers: {} }) {
  if (!response.headers || !state.headers) return

  const correlationIdRegex = /src-correlation-id/i
  const recognitionTokenRegex = /src-recognition-token/i

  const { headers } = response

  for (const key in headers) {
    if (correlationIdRegex.test(key) && !state.headers[HttpHeader.SRC_CORRELATION_ID]) {
      // cache for subsequent calls

      if (state.stark?.srcCorrelationId !== headers[key] && state.stark?.payload) {
        state.stark = Stark.deserialize(state.stark?.serialize(), {
          ...state.stark.payload,
          srcCorrelationId: headers[key]
        })
      }

      state.headers[HttpHeader.SRC_CORRELATION_ID] = headers[key]
    }

    if (recognitionTokenRegex.test(key)) {
      state.headers[HttpHeader.SRC_RECOGNITION_TOKEN] = headers[key]
    }
  }
}

const post = {
  // init post and cache domain and windowRef for all subsequent requests
  init(params: { windowRef: Window; domain: string }) {
    if (!windowRef) {
      windowRef = params.windowRef
    }
    if (!domain) {
      domain = params.domain
    }
  },

  /**
   * This is a wrapper around the request function that prepends /api/ to the url
   * before proceeding to message the iframe to make the appropriate axios call
   *
   * @param message - the request parameters for the axios request
   * @param state - the current SDK state
   */
  async send<T>(message: AxiosRequestConfig, state: State): Promise<T | ErrorResponse> {
    const response = await this.request<T>(
      {
        ...message,
        url: `/api/${message.url}`
      },
      state
    )

    return 'errors' in response ? response : response.data
  },

  /**
   * Send a message to the proxy iframe that makes actual XHR to API
   * IMPORTANT: communicator-frame has its own version set in package.json
   * For breaking changes, update the communicator-frame version to change the file path
   * Stark prepends /api/ already so for stark call request directly, otherwise call the
   * send function which is a wrapper around this request.
   *
   * @param message - the request parameters for the axios request
   * @param state - the current SDK state
   */
  async request<T>(
    message: AxiosRequestConfig,
    state: State
  ): Promise<FakeAxiosResponseOrError<T>> {
    try {
      const { data: response } = await messenger.send<FakeAxiosResponse<T | ErrorResponse>>(
        windowRef,
        'mastercard.proxysend',
        message,
        {
          domain
        }
      )

      const successResponse = response as FakeAxiosResponse<T>
      const errorResponse = response.data as ErrorResponse

      if (errorResponse?.errors) {
        return errorResponse
      }

      cacheHeaders(successResponse, state)

      return successResponse
    } catch (error) {
      /**
       * Regular http errors are handled gracefully by messenger (post-robot).
       *
       * Entering this catch means there was an unexpected / connection error.
       */

      if (isValidError(error)) {
        remoteLogger.error(
          {
            ...events.errors.SDK_TO_IFRAME_COMMUNICATION_ERROR,
            details: {
              message: 'Failed to communicate with the SDK iframe',
              error
            }
          },
          state
        )
      }

      throw new SRCError({
        error: {
          status: 500,
          reason: 'REQUEST_TIMEOUT',
          message: 'Failed to communicate with the iframe'
        }
      })
    }
  },

  /**
   * Fetch nudetect data from the iframe.
   *
   * We need to post message to retrieve it because the hidden nudetect input exists within the
   * iframe, so we can't query that element directly.
   */
  async getNudetectData(state: State): Promise<string> {
    try {
      const response = await messenger.send<string>(
        windowRef,
        'mastercard.get.nudetect',
        undefined,
        { domain }
      )

      return response.data || ''
    } catch (error) {
      if (isValidError(error)) {
        remoteLogger.error(
          {
            event: Errors.NUDETECT_GET_DATA_ERROR,
            details: {
              browser: navigator?.userAgent,
              cause: error?.cause,
              message: 'Failed to get nudetect data from the iframe: ' + error?.toString()
            }
          },
          state
        )
      } else {
        throw error
      }
    }

    return ''
  }
}

export default post
