import licit from '@mc-alberta/licit'
import { validAuthenticationReason } from './emvco/enums'
import generateDpaDataRules from './emvco/generateDpaDataRules'
import generateDpaTransactionOptionsRules from './emvco/generateDpaTransactionOptionsRules'

const { types } = licit

const isSrcDigitalCardIdRequired = (params) => !params?.accountReference?.consumerIdentity
const isConsumerIdentityRequired = (params) => !params?.accountReference?.srcDigitalCardId
const isSrcDpaIdRequired = (params) =>
  params?.authenticationReasons.includes('TRANSACTION_AUTHENTICATION') && !params?.dpaData
const isDpaDataRequired = (params) =>
  params?.authenticationReasons.includes('TRANSACTION_AUTHENTICATION') && !params?.srcDpaId
const isDpaTransactionOptionsRequired = (params) =>
  params?.authenticationReasons.includes('TRANSACTION_AUTHENTICATION')

const isValidAuthenticationReason = (value) => {
  return Array.isArray(value) && value.every((val) => validAuthenticationReason.includes(val))
}

isValidAuthenticationReason.errorDetails = 'Must be an array of valid authentication reasons'

export default [
  { key: 'accountReference', type: types.object, required: true },
  {
    key: 'accountReference.srcDigitalCardId',
    type: types.string,
    required: isSrcDigitalCardIdRequired
  },
  {
    key: 'accountReference.consumerIdentity',
    type: types.object,
    required: isConsumerIdentityRequired
  },
  {
    key: 'authenticationReasons',
    type: isValidAuthenticationReason,
    required: true
  },
  { key: 'srcDpaId', type: types.string, required: isSrcDpaIdRequired },
  { key: 'srcCorrelationId', type: types.string },
  { key: 'srciTransactionId', type: types.string },
  ...generateDpaTransactionOptionsRules({
    areTheyRequired: isDpaDataRequired
  }),
  ...generateDpaDataRules({
    areTheyRequired: isDpaTransactionOptionsRequired
  })
]
